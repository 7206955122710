.account-settings .user-profile {
    margin: 0 0 1rem 0;
    padding-bottom: 1rem;
    text-align: center;
}
.account-settings .user-profile .user-avatar {
    margin: 0 0 1rem 0;
}
.account-settings .user-profile .user-avatar img {
    width: 90px;
    height: 90px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
}
.account-settings .user-profile h5.user-name {
    margin: 0 0 0.5rem 0;
}
.account-settings .user-profile h6.user-email {
    margin: 0;
    font-size: 0.8rem;
    font-weight: 400;
    color: #9FA8B9;
}
.account-settings .about {
    margin: 2rem 0 0 0;
    text-align: center;
}
.account-settings .about h5 {
    margin: 0 0 15px 0;
    color: #007AE1;
}
.account-settings .about p {
    font-size: 0.825rem;
}
.form-control {
    border: 1px solid #CFD1D8;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    font-size: .825rem;
    background: #FFFFFF;
    color: #2E323C;
}
.alert-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    color: #f5f4f4;
    border: 1px solid #F5C6CB;
    padding: 15px 20px;
    border-radius: 5px;
    z-index: 9999;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .close-button {
    background: none;
    border: none;
    cursor: pointer;
  }
  .close-button span {
    font-size: 20px;
    font-weight: bold;
    color: #721C24;
  }

  @media screen and (max-width: 768px) and (min-width: 360px) {
    
  }
