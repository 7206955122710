.ft{
    white-space: nowrap;
    font-size: 14px;
}
#yp{
    margin-left: 5%;
}
@media screen and (max-width: 768px) {
    .ft{
        font-size: x-small;
    }
    #yp{
        white-space: nowrap;
        font-size: x-large;
        margin-left: 2px;
    }
}
@media screen and (max-width: 360px){
    #yp{
        
        font-size:larger;
        margin-left: 4px;
    }
  }

  @media screen and (min-width: 1760px){
    #yp{
        margin-left: 13%;
    }
  }
