

/* Default styles for larger screens */
.main-div {
    background-color: lightblue;
  }
  .kiu{
    margin-left: 60px;
    height:35px;
     margin-top: 30px;
  }
  .signupdiv {
    height: 106%;
    width: 500px;
    background-color: white;
    margin-top: -25px;
    border-radius: 10px;
    /* Add other styles as needed */
  }
  #fcheck{
    margin-left: -3%;
  }
  #rd{
    margin-top: -9px;
    font-size:8.5px ;
    margin-left: 17%;
  }
  .formm{
    margin-left: 60px;
  }
  h4,
  p {
    font-family: "Montserrat", sans-serif;
  }
  #hiu{
    text-align: center;
    margin-top: -20px;

  }
  @media (max-width:360px){
    .partners1 {
      margin-left: 2%;
      margin-top: 3%;
    }
  }
  
  /* Media query for tablets and smaller screens */
  @media (max-width: 768px) {
    .logindiv {
      width: 136%;
      max-width: 600px;
      margin: 0 auto;
      margin-top: -44%;
      height: 134%;
      margin-left: -19%;
    }
    #rd{
      width: 73%;
    margin-left: 27%;
    margin-top: -9%;
    }
    .formm{
      margin-left: 76px;
    }
    #imggv{
      margin-left: 13%;
    }
  }
  
  @media screen and (max-width: 360px){
    .image1{
      
      width: 77%;
  }
  }
  /* Media query for mobile devices */
  @media (max-width: 576px) {
    .signupdiv {
      width: 136%;
      max-width: 600px;
      margin: 0 auto;
      margin-top: 20px;
      height: 100%;
      margin-left: -19%;
    }
    #imggv{
      margin-left: 23%;
      margin-top: 10%;
      width: 82%;
    }
    
    #rd{
      width: 73%;
    margin-left: 23%;
    margin-top: -4%;
    }
    .formm{
      margin-left: 45px;
    }
    .image1{
        margin-left: 150px;
        margin-top: 50px;
        width: 77%;
    }
    .mrgo{
      margin-left: 40%;
      margin-top: -8%;
  }
  
    h1 {
      font-size: 24px;
    }
  
    h2 {
      font-size: 18px;
    }
    .imagediv{
      margin-left: -63%;
    }
  
    h4 {
      font-size: 14px;
      padding-top: 20px;
    }
    Form.Control{
        margin-left: 20px;
    }
    #hiu{
      white-space: nowrap;
      margin-left: -1%;
    }
  #d1{
    margin-left: 4%;
  }
    /* Make background color responsive */
    .main-div {
      background-color: lightblue;
      width: 100%;
      height: 100%;
    }
    
    .welcome{
        margin-left: 47%;
        width: 59%;
    }
    .partners{
        margin-left: 44%;
        margin-top: 20px;
    }
    .partners1{
        margin-left: 1%;
        margin-top: 20px;
    }
    
.kiu{
  margin-left: 35px;
}
  }
  @media only screen and (min-width: 580px) and (max-width: 671px) {
   
  }


  .invalid-feedback{
    margin-left: 13%;
    width: 75%;
  }

  @media screen and (min-width: 359px) and (max-width: 767px) {
    #jirr{
  margin-top: 11%;
    }
    .mrgo{
      margin-left: 48%;
    }
    #hiu{
      margin-left: 9%;
    }
  }

  @media screen and (min-width: 990px) and (max-width: 1608px) {
    #dtdcsign{
      margin-left: -3px;
    }
    #indianpostsign{
margin-left: 4px;
    }
  }

