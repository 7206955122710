
  #iop{
    padding-top: 90px;
    font-size: 16px;
    line-height: 22px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    background-color: #f7fafd;
  }
  
  @media (max-width: 39.9375em) {
    #iop {
      padding-top: 0;
      font-size: 13px;
      line-height: 16px;
    }
  }
  
  p {
    padding: 0;
    margin: 0;
  }
  
  .notifications-container {
    background-color: white;
    border-radius: 13px;
    padding: 30px;
    max-width: 731px;
  }
  
  @media (max-width: 39.9375em) {
    .notifications-container {
      border-radius: 0;
      padding-right: 16px;
      padding-left: 16px;
    }
  }
  
  .notifications-container .header .title {
    font-weight: 800;
    font-size: 19px;
  }
  
  @media (max-width: 280px) {
    .notifications-container .header .title {
      font-size: 13px;
    }
  }
  
  .notifications-container .header .title .unread-notifications-number {
    color: white;
    background-color: #0a317b;
    padding: 2px 13px;
    border-radius: 5px;
    margin-left: 12px;
  }
  
  @media (max-width: 280px) {
    .notifications-container .header .title .unread-notifications-number {
      font-size: 12px;
      padding: 2px 10px;
      margin-left: 5px;
    }
  }
  
  .notifications-container .header .mark-as-read .mark-as-read-button {
    color: #5e6778;
    text-decoration: none;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
  }
  
  @media (max-width: 280px) {
    .notifications-container .header .mark-as-read .mark-as-read-button {
      font-size: 10px;
    }
  }
  
  .notifications-container .header .mark-as-read .mark-as-read-button:hover {
    color: #0a317b;
  }
  
  .notifications-container .notifications {
    margin: 10px 0;
  }
  
  .notifications-container .notifications .single-notification-box {
    padding: 17px;
    border-radius: 7px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: none;
  }
  
  .notifications-container .notifications .single-notification-box .profile-picture {
    width: 45px;
    height: 45px;
    padding: 0;
  }
  
  .notifications-container .notifications .single-notification-box .profile-picture img {
    height: 100%;
  }
  
  .notifications-container .notifications .single-notification-box .clickable-image {
    border: none;
    border-radius: 6px;
  }
  
  .notifications-container .notifications .single-notification-box .clickable-image:hover {
    outline: 2px solid #e5effa;
    outline-offset: 2px;
    cursor: pointer;
  }
  
  .notifications-container .notifications .single-notification-box .notification-text {
    padding: 0 0 0 14px;
  }
  
  @media (max-width: 39.9375em) {
    .notifications-container .notifications .single-notification-box .notification-text {
      width: 85%;
      padding: 0 14px 0 14px;
    }
  }
  
  @media (max-width: 366px) {
    .notifications-container .notifications .single-notification-box .notification-text {
      width: 78%;
      padding: 0 14px 0 14px;
    }
  }
  
  .notifications-container .notifications .single-notification-box .notification-text .link {
    text-decoration: none;
    font-weight: 800;
    color: #5e6778;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    margin-right: 5px;
  }
  
  .notifications-container .notifications .single-notification-box .notification-text .link:hover {
    color: #0a317b;
  }
  
  .notifications-container .notifications .single-notification-box .notification-text .name {
    color: #000000;
  }
  
  .notifications-container .notifications .single-notification-box .notification-text .description {
    color: #5e6778;
    margin-right: 5px;
  }
  
  .notifications-container .notifications .single-notification-box .notification-text .time {
    color: #939dae;
  }
  
  @media (max-width: 39.9375em) {
    .notifications-container .notifications .single-notification-box .notification-text .time {
      margin-top: 5px;
    }
  }
  
  .notifications-container .notifications .single-notification-box .notification-text .private-message {
    padding: 20px;
    border: 1px solid #e5effa;
    border-radius: 7px;
    margin-top: 10px;
    color: #5e6778;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
  }
  
  @media (max-width: 39.9375em) {
    .notifications-container .notifications .single-notification-box .notification-text .private-message {
      padding: 15px;
    }
  }
  
  .notifications-container .notifications .single-notification-box .notification-text .private-message:hover {
    background-color: #e5effa;
    cursor: pointer;
  }
  
  @media (max-width: 39.9375em) {
    .notifications-container .notifications .single-notification-box .comment {
      width: 70%;
    }
  }
  
  @media (max-width: 366px) {
    .notifications-container .notifications .single-notification-box .comment {
      width: 69%;
    }
  }
  
  @media (max-width: 280px) {
    .notifications-container .notifications .single-notification-box .comment {
      width: 55%;
    }
  }
  
  .notifications-container .notifications .unread {
    background-color: #f7fafd;
  }
  
  .notifications-container .notifications .unread .unread-symbol {
    font-size: 20px;
    color: #f65351;
    display: inline;
  }
  
  @media (max-width: 39.9375em) {
    .notifications-container .notifications .unread .unread-symbol {
      font-size: 16px;
    }
  }
  
  .notifications-container .notifications .read {
    background-color: none;
  }
  
  .notifications-container .notifications .read .unread-symbol {
    display: none;
  }
  
  .attribution {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  /*# sourceMappingURL=styles.css.map */