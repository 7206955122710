.user-table-container {
  max-width: 800px;
  margin: 0 auto;
}
table {
  width: 100%;
  border-collapse: collapse;
}
th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}
th {
  background-color: #F2F2F2;
}
tr:nth-child(even) {
  background-color: #F2F2F2;
}
tr:hover {
  background-color: #ddd;
}
#bnjj{
  margin-top: -1%;
  height: 70%;
  padding-top: 2px
}
.no-data-cell {
  text-align: center;
  padding: 10px;
  color: #999; /* Change color as desired */
  font-style: italic;
}
.search-input2 {
  width: 25%;
  padding: 1%;
  border: 1px solid #2BFDDE;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}
.search-input2:focus {
  outline: none;
  border-color: dodgerblue;
}
.sort{
  margin-inline-start: auto;
}
.sort-button {
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-inline-start: auto;
}
.sort-button:hover {
  background-color: #0056B3;
}
.sort-icon {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 6px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
.sort-icon.asc {
  border-bottom: 5px solid #fff; /* Upward arrow */
}
.sort-icon.desc {
  border-top: 5px solid #fff; /* Downward arrow */
}





.search-container {
  position: relative;
}

.search-input {
  width: 100%;
  padding: 10px 20px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 25px; /* Adjust border radius to match your design */
  outline: none;
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  color: #555;
}

.search-input:focus {
  border-color: #007bff; /* Adjust focus border color to match your design */
}
/* Style for table */
.table {
  width: 100%;
  border-collapse: collapse;
}

/* Style for table head */
.table thead th {
  background-color: #f8f9fa; /* Light grey */
  border: 1px solid #dee2e6; /* Light grey border */
  padding: 8px;
  text-align: left;
}

/* Style for table body */
.table tbody td {
  border: 1px solid #dee2e6; /* Light grey border */
  padding: 8px;
}

/* Style for alternating row colors */
.table tbody tr:nth-child(even) {
  background-color: #f2f2f2; /* Lighter grey */
}

/* Style for "No data" cell */
.no-data-cell {
  text-align: center;
}

/* Style for actions column */
.actions-column {
  display: flex;
  justify-content: space-around;
}

/* Style for select dropdowns */
.select-dropdown {
  width: 100%;
  padding: 6px;
  border: 1px solid #ced4da; /* Light grey border */
  border-radius: 4px;
  background-color: #fff;
  font-size: 14px;
}

/* Style for loading text */
.loading-text {
  font-style: italic;
  color: #6c757d; /* Dark grey */
}

/* Style for edit and delete icons */
.material-icons {
  cursor: pointer;
}

/* Style for edit icon */
.edit-icon {
  color: #007bff; /* Blue */
}

/* Style for delete icon */
.delete-icon {
  color: #dc3545; /* Red */
}


.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table th,
.custom-table td {
  padding: 8px;
  border: 1px solid #ff0000;
  text-align: left;
}

.custom-table th {
  background-color: #f2f2f2;
}

.custom-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.custom-table tbody tr:hover {
  background-color: #e9e9e9;
}

.no-data-cell {
  text-align: center;
  font-style: italic;
  color: #ff0000;
}
.user-table-container {
  max-width: 800px;
  margin: 0 auto;
}

.search-container {
  position: relative;
  margin-bottom: 20px;
}

.search-input {
  width: 100%;
  padding: 10px 20px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 25px;
  outline: none;
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  color: #555;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table th,
.custom-table td {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: left;
}

.custom-table th {
  background-color: #f2f2f2;
}

.custom-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.custom-table tbody tr:hover {
  background-color: #ddd;
}

.no-data-cell {
  text-align: center;
  padding: 10px;
  color: #999;
  font-style: italic;
}

.actions-column {
  display: flex;
  justify-content: space-around;
}

.select-dropdown {
  width: 100%;
  padding: 6px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  background-color: #fff;
  font-size: 14px;
}

.material-icons {
  cursor: pointer;
}

.edit-icon {
  color: #007bff;
}

.delete-icon {
  color: #dc3545;
}

#lplpk{
  margin-left: -5%;
}


@media only screen and (max-width: 1199px) { 
  #lplpk{
    margin-left: 0%;
  }
        }