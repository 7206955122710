.text-secondary-d1 {
    color: #728299!important;
}
.page-header {
    margin: 0 0 1rem;
    padding-bottom: 1rem;
    padding-top: .5rem;
    border-bottom: 1px dotted #E2E2E2;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
}
.page-title {
    padding: 0;
    margin: 0;
    font-size: 1.75rem;
    font-weight: 300;
}
.brc-default-l1 {
    border-color: #DCE9F0!important;
}
.ml-n1, .mx-n1 {
    margin-left: -.25rem!important;
}
.mr-n1, .mx-n1 {
    margin-right: -.25rem!important;
}
.mb-4, .my-4 {
    margin-bottom: 1.5rem!important;
}
hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.1);
}
.text-grey-m2 {
    color: #888A8D!important;
}
.text-success-m2 {
    color: #86BD68!important;
}
.font-bolder, .text-600 {
    font-weight: 600!important;
}
.text-110 {
    font-size: 110%!important;
}
.text-blue {
    color: #478FCC!important;
}
.pb-25, .py-25 {
    padding-bottom: .75rem!important;
}
.pt-25, .py-25 {
    padding-top: .75rem!important;
}
.bgc-default-tp1 {
    background-color: rgba(121,169,197,.92)!important;
}
.bgc-default-l4, .bgc-h-default-l4:hover {
    background-color: #F3F8FA!important;
}
.page-header .page-tools {
    -ms-flex-item-align: end;
    align-self: flex-end;
}
.btn-light {
    color: #757984;
    background-color: #F5F6F9;
    border-color: #DDDFE4;
}
.w-2 {
    width: 1rem;
}
.text-120 {
    font-size: 120%!important;
}
.text-primary-m1 {
    color: #4087D4!important;
}
.text-danger-m1 {
    color: #DD4949!important;
}
.text-blue-m2 {
    color: #68A3D5!important;
}
.text-150 {
    font-size: 150%!important;
}
.text-60 {
    font-size: 60%!important;
}
.text-grey-m1 {
    color: #7B7D81!important;
}
.align-bottom {
    vertical-align: bottom!important;
}






@media print {
    .hide-on-print {
        display: none;
    }
}
