.sidebar {
    position: fixed;
    top: 0;
    left: -300px; /* Initially off-screen */
    width: 300px;
    height: 100%;
    background-color: #f4f4f4;
    z-index: 1000;
    transition: left 0.3s ease;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.sidebar.show {
    left: 0; /* Slide in from the left when show class is added */
}

.sidebar h3 {
    margin-bottom: 20px;
}

.sidebar button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: none;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
}

.sidebar button:hover {
    background-color: #0056b3;
}
.sidebar {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    transform: translateX(-100%);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 250px;
    z-index: 1000;
}

.sidebar.show {
    transform: translateX(0);
}

h3 {
    margin-bottom: 10px;
}

.select-dropdown {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    font-size: 16px;
    outline: none;
}

.select-dropdown:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
/* Existing styles */

.sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.close-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    font-size: 20px;
    color: #555;
}

.close-button:hover {
    color: #000;
}
