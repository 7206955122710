/* App.css */ 
body { 
  margin: 0; 
  padding: 0; 
  min-height: 100vh; 
  display: flex; 
  flex-direction: column; 
} 
 
.App { 
  flex: 1; 
} 
 
.footer { 
  display: flex; 
  flex-wrap: wrap; 
  background-color: #333; 
  color: white; 
  padding: 20px; 
  margin-top: auto; /* Push the footer to the bottom */ 
} 
.mns{ 
    font-family: "Montserrat", sans-serif; 
    font-size: 30px; 
    font-weight: 900; 
    line-height: 36px; 
    letter-spacing: 0em; 
    text-align: left; 
     
  color: red; 
} 
.bb{ 
font-family: "Montserrat", sans-serif; 
font-size: 15px; 
font-weight: 400; 
line-height: 18px; 
letter-spacing: 0em; 
text-align: left; 
color: #FFFFFF99; 
 
 
} 
.fc21{ 
    font-family: "Montserrat", sans-serif; 
    font-size: 16px; 
font-weight: 400; 
line-height: 20px; 
letter-spacing: 0em; 
text-align: left; 
color: #FFFFFF; 
 
} 
.fc2{ 
    font-family: "Montserrat", sans-serif; 
    font-size: 20px; 
font-weight: 400; 
line-height: 24px; 
letter-spacing: 0em; 
text-align: left; 
color:  #FFFFFF; 
 
 
} 
.bbb{ 
    font-family: "Montserrat", sans-serif; 
    font-size: 15px; 
font-weight: 400; 
line-height: 18px; 
letter-spacing: 0em; 
text-align: left; 
color: #FFFFFF; 
 
 
} 
.column { 
  flex: 1; 
  margin: 10px; 
} 
 
.column h3 { 
  color: #61dafb; /* React's official color */ 
} 
#mnss{ 
  margin-left: 54px; 
} 
 
/* Responsive Styles */ 
@media screen and (max-width: 768px) { 
  .column { 
    flex: 100%; 
  } 
  #foo{ 
    margin-left: -38px; 
  } 
  #mnss{ 
    margin-left: 16px; 
  } 
  #njk{ 
    margin-left: -35px; 
  } 
  #njkk{ 
    margin-left: -39px; 
    font-size: 13px; 
  } 
  .fc2{ 
    margin-left: -38px; 
    margin-top: -30px; 
     
  } 
  .fc21{ 
    margin-left: -38px; 
  font-size: 14px; 
  } 
  #e3{ 
    margin-left: 169px; 
    color: #cfc5c5; 
  } 
  #g1{ 
    margin-left: 134px; 
  } 
  #g2{ 
    margin-left: 134px; 
  } 
  #g3{ 
    margin-left: 134px; 
    font-size: smaller; 
  } 
  #g4{ 
    margin-left: 134px; 
  } 
  #op{ 
    margin-top: -226px; 
     
  } 
  #fw{ 
    margin-top: -14px; 
  } 
  #jk{ 
    color: #cfc5c5; 
  } 
  #ji{ 
    color: #cfc5c5; 
  } 
} 

@media screen and (max-width: 394px) { 
  .footer{ 
    margin-left: -27px; 
  } 
  #mnss { 
    margin-left: 60px; 
} 
#foo { 
  margin-left: 8px; 
} 
#njk { 
margin-left: 7px; 
} 
#njkk { 
margin-left: 9px; 
font-size: 13px; 
} 
#jk { 
color: #cfc5c5; 
margin-left: 47px; 
} 
.fc21 { 
margin-left: 7px; 
font-size: 14px; 
} 
#e3 { 
margin-left: 64%; 
color: #cfc5c5; 
} 
#g1 { 
margin-left: 59%; 
} 
#g2 { 
margin-left: 59%; 
} 
#g3 { 
margin-left: 59%; 
} 
#g4 { 
margin-left: 59%; 
} 
#ji { 
color: #cfc5c5; 
margin-left: 45px; 
} 

}