@media (max-width: 1199px) {
  .losign {
    width: 30%;
  }
}

.losign {
  font-weight: bold; 
}

.navbar-expand-xl .navbar-nav .nav-link {
  color: black !important;
}

.offcanvas-body {
  margin-left: -4%;
}

@media only screen and (min-width: 350px) and (max-width: 1199px) {
  #brder {
    margin-top: 10px;
    margin-left: 20px;
  }
  .losign {
    margin-top: 10px;
  }
  .brder {
    margin-top: 10px;
    margin-left: 20px;
  }
}

#hhome{
    margin-right: 4%;
}
.brder{
    margin-right: 4%;

}
 