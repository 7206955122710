/* ShippingRegistration.css */

.shipping-registration {
  padding: 20px;
  background-image: url(https://i.postimg.cc/157rpkFF/FREE-HDconvert-com-Shipping.png);
  /* width: 1300px; */
  background-attachment: fixed;
  background-size: cover;
  height: 100%;
  margin-top: -2% ;

}

.rows {
  width: 40%;
  height: 100%;
  border-color: black;
  border-radius: 9px;
  background-color: rgba(248, 249, 250, 0.7);
  margin-left: 350px;
  
}

/* Responsive Styles */

/* Larger screens */
@media (min-width: 1200px) {
  .rows {
    width: 45%;
    margin-left: 55%;
    
  }
}

/* Medium screens */
@media (max-width: 1199px) and (min-width: 768px) {
  .rows {
    width: 50%;
    margin-left: 25%;
    height: 100%;
  }
}

/* Small screens */
@media (max-width: 767px) {
  .rows {
    width: 80%;
    margin-left: 10%;
  }
}

/* Extra Small screens */
@media (max-width: 576px) {
  .rows {
    width: 100%;
    margin-left: 0;
    height: 100%;
  }
}
@media (max-width: 757px) {
  .shipping-registration{
    margin-top: -4%;
  }
}

.loading-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  color: black;
}
