.container2 {
  max-width: 75%;
  margin: auto;
  padding: 20px;
  border-radius: 20px; /* Increase border radius */
  background-color: #d2f1ea; /* Change background color */
  box-shadow: 0 0 10px rgba(33, 10, 10, 0.1); /* Add box shadow */
  margin-top: 1%;
}
.container2:hover{
  transform: scale(1.01);
}
.info-item {
  margin-bottom: 15px; /* Increase margin bottom */
  color: #333; /* Change text color */
  font-size: 16px; /* Change font size */
}
.tick-button {
  background-color: #007BFF; /* Default button background color */
  color: #fff; /* Default button text color */
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 14px;
  transition: background-color 0.3s ease;
}
.tick-button.ticked {
  background-color: #28A745; /* Background color when ticked */
}
.tick-button:disabled {
  background-color: #6C757D; /* Background color when disabled */
  cursor: not-allowed;
  border-radius: 10px;

}
.btn11{
  margin-left: 86%;
  margin-top: -78px;
}
.confirmation-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.confirmation-content {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 400px;
}
.confirmation-content p {
  margin-bottom: 20px;
  font-size: 18px;
}
.confirmation-content button {
  margin: 0 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}
.confirmation-content button:hover {
  background-color: #F0F0F0;
}




