/* CardComponent.css */

.card-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .cardt {
    flex: 0 0 18%;
    margin: 10px;
    padding: 20px;
    border: 1px solid #ccc;
    text-align: center;
  }
  .bh{
    height: 179px;
  }
  .thy{
    width: 156%;
    margin-left: -27%;
  }
  
  @media screen and (max-width: 768px) {
    .cardt {
      flex: 0 0 43%; /* Adjust the width as needed for smaller screens */
    }
    .bh{
        height: 170px;
        margin-top: -30px;
    }
    .thy{
        font-size: x-small;
        width: 128%;
        margin-left: -28%;
    }
    .vbnn{
        margin-left: 27%;
    }
  }
  @media screen and (max-width: 360px) {
    .cardt {
      flex: 0 0 43%; /* Adjust the width as needed for smaller screens */
    }
    .thy{
      width: 214%;
      margin-left: -59%;
    }
    .bh{
      height: 179px;
   
  }
  }
  @media screen and (min-width: 769px) and (max-width: 1039px) {
    .cardt {
      flex: 0 0 43%; /* Adjust the width as needed for smaller screens */
    }
    .thy{
      width: 100%;
      margin-left: -9%;
      font-size: larger;
      margin-top: -12px;
    }
    .bh{
      height: 179px;
   
  }
  .vf{
    white-space: nowrap;
  }
  #cd1{
    margin-left: 38%;
  }
  #cd2{
    margin-left: 38%;
  }
  #cd3{
    margin-left: 38%;
  }
  #cd4{
    margin-left: 38%;
  }
  #cd5{
    margin-left: 38%;
  }
  }
  @media screen and (min-width: 361px) and (max-width: 430px) {
    .thy{
      width: 218%;
      margin-left: -57%;
    }
  }

  @media screen and (min-width: 1510px)  {
    .thy {
      width: 130%;
      margin-left: -16%;
  }
  }