.containerb {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 30px;
    width: 300px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
  }
  @media (max-width: 768px) {
    .containerb {
        width: 250px; /* Adjust width for smaller screens */
    }
  }
  h2 {
    margin-bottom: 20px;
    color: #333;
  }
  input[type="password"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
  }
  button {
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  /* button:hover {
    background-color: #0056B3;
  } */