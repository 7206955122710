/* ThreeCardsComponent.css */

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px;
    

  }
 
  #cards{
    margin-left: 5%;
  }
  
  .vc {
    width: 30%; /* Adjust the width as needed for the desired gap between cards */
    margin-bottom: 20px;
    /* border: px solid #ccc; */
    /* border-radius: 8px; */
    /* flex-basis: calc(30% - 20px); Considering a 20px margin between cards */

    overflow: hidden;
    border-radius: .5px; /* Adjust the radius as needed */
    padding: 20px;
    
    text-align: center;
    box-shadow: 
    -5px -5px 5px rgba(0, 0, 0, 0.1), /* Top-left corner */
    5px -5px 5px rgba(0, 0, 0, 0.1), /* Top-right corner */
    -5px 5px 5px rgba(0, 0, 0, 0.1), /* Bottom-left corner */
    5px 5px 5px rgba(0, 0, 0, 0.1); /* Bottom-right corner */
    
  }
  
  .card img {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #ccc;
  }
  
  .card p {
    padding: 10px;
    text-align: justify;
  }
  .rounded-box {
    width: 80px;
    height: 80px;
    background-color:  #FFC7C2;
    border-radius: 170px; /* Adjust the radius as needed */
    padding: 20px;
    color: white;
    text-align: center;
    justify-content: center;
    margin-left: 33%;
  }
  .card:hover{
    
    /* Add other styles for the hover effect */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    /* Add more styles as needed */
  }
  h5{
    font-family: 'Montserrat',sans-serif;
font-size: 24px;
font-weight: 600;
line-height: 29px;
letter-spacing: 0em;
text-align: center;

  }
  .ca{
    font-family: Montserrat;
font-size: 12px;
font-weight: 500;
line-height: 20px;
letter-spacing: 0em;


  }
  
  @media (max-width: 768px) {
    .vc {
      width: 57%;
       margin-left: -68px;
       margin-right: 9px;
    }
    .card img {
      width: 175%;
      height: 145%;
      margin-left: -44%;
      margin-top: -116%;
    }
    #cdr{
      height: 170px;
    }
    #cards{
      
     width: 74%;
     margin-left: 22%;
    }
    .rounded-box {
      height: 52px;
      width: 52px;
      margin-top: -10px;
      margin-left:25%;
    }
    .cdp{
      font-size: small;
      white-space: nowrap;
    }
    .ca{
      margin-left: -7px;
    width: 130%;
    font-size: 6px;
    margin-top: -21px ;
    line-height: 10px;
    }
  }
  .rounded-box2{
    background-color:  #E4CCFF;

  }
  .rounded-box3{
    

    background-color:  #FFE8A3;
  }
  #ik{
    font-size: smaller;
  }
  .rounded-box4{

    background-color:  #AFF4C6;

  }
 
  .rounded-box5{
    background-color:  #BDE3FF;
  }
  .rounded-box6{
    background-color:  #FCD19C;
  }

  @media screen and (min-width:769px) and (max-width: 991px){
    #cards{
      margin-left: 10%;
    }
  }
  /* @media screen and  (max-width: 1199px){
    #cards{
      margin-left: 6%;
    }
  } */
  @media screen and  (max-width: 768px) and (min-width:550px){
    #cards{
      margin-left: 15%;
    }
    #cdr {
      height: 170px;
      width: 49%;
      margin-left: -4%;}

  }

  @media (min-width: 1560px) {
    #cards {
justify-content: space-around; 
margin-left: 10%;
}
  }

  @media (max-width: 476px) {
    .vc {
        width: 100%; /* Full width for single column */
        margin-left: 0; /* Reset margins for centering */
        margin-right: 0; /* Reset margins for centering */
    }

    .card img {
        width: 28px; /* Full width for image */
        height: 100%; /* Maintain aspect ratio */
        margin-left: -8px; /* Reset margins */
        margin-top: -9px; /* Reset margins */
    }

    #cards {
        width: 100%; /* Full width for container */
        margin-left: 0; /* Reset margins */
    }

    .rounded-box {
        margin-left: auto; /* Center the box */
        margin-right: auto; /* Center the box */
    }

    .ca {
        width: 100%; /* Full width for text */
        margin-left: 0; /* Reset margins */
        font-size: 9px;
        margin-top: -7px;
    }
}

