.containerm {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-top: 40px;
    width: 350px;
    height: 390px;
    margin-left: auto;
    margin-right: auto;
  }
    .containerm h2 {
      margin-bottom: 10px;
      color: #333;
    }
    .containerm p {
      margin-bottom: 20px;
      color: #666;
    }
    .otp-inputs {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }
    .otp-inputs input {
      width: 40px;
      height: 40px;
      margin: 0 5px;
      font-size: 20px;
      text-align: center;
      border: 1px solid #ccc;
      border-radius: 5px;
    }
    .resend {
      margin-bottom: 20px;
    }
    .resend a {
      color: #007BFF;
      text-decoration: none;
    }
    button {
      background-color: #007BFF;
      color: #fff;
      border: none;
      border-radius: 5px;
      padding: 10px 20px;
      font-size: 16px;
      cursor: pointer;
    }
    /* button:hover {
      background-color: #0056B3;
    } */