.features-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; /* Adjust as needed */
    
}
.zz{
  margin-left: -17px;
}

.feature {
    width: calc(50% - 10px); /* Adjust as needed */
    margin-bottom: 20px; /* Adjust as needed */
}
.image123{
    margin-left:-22px;
   
}
.card{
height: 10%;
}
#jm{
  height: 200px;
    width: 200px;
    padding: 2px;
    transition:0.3s;
}
.nmh{
 height: 200px;
 width: 200px;
 padding: 2px;
 transition: 0.3s;
}
.nextdiv{
    width: 100%;
    height:100%;
    background-color: lightblue;
    font-size: smaller;
}

.rounded-boxr {
    width: 60px;
    height: 60px;
    border-radius: 170px; /* Adjust the radius as needed */
    padding: 20px;
    color: white;
    background-color: #ff0000;
    text-align: center;
    justify-content: center;
    margin-left: 47px;
   
    
  }
  #cd1{
    background-color: rgba(252, 209, 156, 1)
    ;

  }
  #cd2{
    background-color: rgba(189, 227, 255, 1);
    background-color: rgba(228, 204, 255, 1)
    ;
  }
  .kl{
    margin-left: 42px;
  }
  #cd3{
   
    background-color: rgba(228, 204, 255, 1)
    ;
  }
  #cd4{
    background-color:rgba(255, 199, 194, 1);
  
    ;
  }
  #cd5{
    background-color: rgba(175, 244, 198, 1);
 
    ;
  }
  
  @media screen and (max-width: 768px) {
    
    #bvg{margin-left:'32px';
    }
    .rounded-boxr{
      margin-top: 10px;
    }
    .oo{
      margin-left: -20%;
    }
    #cd2{
      margin-left: 7%;
    }
    #cd3{
      margin-left: 7%;
    }
    .hu{
      margin-left: -22px;
    }
    #cd4{
      margin-left: 7%;
    }
    .b2bc{
      margin-left: -13px;
    }
    #cd5{
      margin-left: 7%;
    }
    .feature {
      width: 90%; /* Adjust as needed */
     
  }
  featured{
    width: 100%;
  }
  .ed{
    width: 115%;
    margin-left: -15%;
  }
    .pp33{
      width: 100%;
      font-size: small;
      margin-left: -9px;
    }
    .mpko{
      width: 121%;
    }
    .nextdiv{
      height: 102%;
    }
    /* .jm{
      width: 89%;
    }
    #jm{
      margin-left: 9%;
      width: 89%;
    } */
    /* .card{
      margin-left: 16px;
      width: 90%;
    } */
    #cd1{
      background-color: rgba(252, 209, 156, 1)
      ;
      margin-left: 9%;
      
  
    }
    /* #qwe{
      margin-left: 13%;
      width: 77%;
    } */

    /* #qwee{
      margin-left: 5%;
    } */
    /* #qweee{
      margin-left: 5%;
    } */
    /* #qws{
      margin-left: 5%;
    } */
    #cpi{
      width: 311px;
    }
    .kl{
      width: 89%;
      margin-left: 10px;
    }
    .image123{
      margin-left:168px;
      margin-top: 10px;
      display: none;
     }
     .img{
      display: none;
     }
  }

  @media only screen and (min-width: 769px) and (max-width: 1200px){
    .expi{
      width: 111%;
      height: 88%;
    }
  }

  @media screen and (min-width: 1360px) {
    .expi{
      margin-left: 16%;
    }
  }
