.container {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
  }
  
  h2 {
    font-size: 26px;
    margin: 20px 0;
    text-align: center;
    small {
      font-size: 0.5em;
    }
  }
  
  .responsive-table {
    li {
      border-radius: 3px;
      padding: 25px 30px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;
    }
    .table-header {
      background-color: #95A5A6;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 0.03em;
    }
    .table-row {
      background-color: #ffffff;
      box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.1);
    }
    .col-1 {
      flex-basis: 10%;
    }
    .col-2 {
      flex-basis: 40%;
    }
    .col-3 {
      flex-basis: 25%;
    }
    .col-4 {
      flex-basis: 25%;
    }
    
    @media all and (max-width: 767px) {
      .table-header {
        display: none;
      }
      
      li {
        display: block;
      }
      .col {
        
        flex-basis: 100%;
        
      }
      .col {
        display: flex;
        padding: 10px 0;
        &:before {
          color: #6C7A89;
          padding-right: 10px;
          content: attr(data-label);
          flex-basis: 50%;
          text-align: right;
          white-space: nowrap;
        }
      }
    }
  }

  /* Responsive styles for UserInvoice component */

/* Responsive styles for UserInvoice component */

/* Adjust layout for small screens */
@media only screen and (max-width: 600px) {
  .container {
    width: 100%;
    padding: 0 10px;
  }
  
  .search-input2 {
    width: 100%;
    margin-bottom: 10px;
  }
  
  .responsive-table {
    overflow-x: auto;
    width: 100%;
    border-collapse: collapse;
  }
  
  .responsive-table .table-header {
    background-color: #f2f2f2;
    font-weight: bold;
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .responsive-table .table-row {
    border: 1px solid #ddd;
    padding: 8px;
    white-space: nowrap;
  }
  
  .responsive-table .col {
    display: block;
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid #ddd; /* Add border between rows */
  }
}

/* Styles for larger screens */
@media only screen and (min-width: 601px) {
  .container {
    width: 80%;
    margin: 0 auto;
  }

  .responsive-table .table-header {
    display: flex; /* Show table header on larger screens */
    background-color: #b8b8b8;
    font-weight: bold;
    border: 1px solid #ddd;
    padding: 8px;
  }

  .responsive-table .table-row {
    background-color: #fff;
    padding: 0px;
  }

  .responsive-table .table-row:nth-of-type(even) {
    background-color: #f2f2f2;
    padding: 0px;
  }

  .responsive-table .col {
    display: table-cell;
    text-align: left;
    padding: -1px;
  }
}
